<div class="flex">
  <div class="data">
    <img src="../../../../assets/images/tick.svg" style="margin-right: 2%" />
    {{ data | translate }}
  </div>
  <!-- <div class="dismiss">
      <button mat-icon-button (click)="snackBarRef.dismiss()">
          <mat-icon>close</mat-icon>
        </button>
    </div> -->
</div>
