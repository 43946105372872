import { NgModule } from "@angular/core";
import { CommonModule, NgStyle } from "@angular/common";
import { HomeHeaderComponent } from "./home-header/home-header.component";
import { MaterialModule } from "../material.module";
// import { AvatarModule } from "ngx-avatar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MultipleErrorMessageComponent } from "./multiple-error-message/multiple-error-message.component";
import { ErrorMessageComponent } from "./error-message/error-message.component";
import { DragAndDropFileDirective } from "./drag-and-drop-file/drag-and-drop-file.directive";
import { CustomToolTipComponent } from "./custom-tool-tip/custom-tool-tip.component";
import { ToolTipRendererDirective } from "./tool-tip-renderer/tool-tip-renderer.directive";
import { AvatarComponent } from "./avatar/avatar.component";

@NgModule({
  declarations: [
    HomeHeaderComponent,
    ErrorMessageComponent,
    MultipleErrorMessageComponent,
    DragAndDropFileDirective,
    CustomToolTipComponent,
    ToolTipRendererDirective,
    AvatarComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgStyle,
    // AvatarModule.forRoot(),
  ],
  exports: [
    HomeHeaderComponent,
    DragAndDropFileDirective,
    ToolTipRendererDirective,
    AvatarComponent,
  ],
})
export class SharedModule {}
