import { pdfBody } from "../dashboard.component";

export function reportDashboardTemplate(body: pdfBody, page: string): string {
  return `
    <body class="pdf-page">
        <header class="p-3">

            <img width="170" class="ms-4" src="../../../assets/images/hola_doc_logo_template.svg" />
            <div class="container-header mt-4" style="flex-grow:1">
                <p class="m-0"> 
                    Reporte de indicadores clave HolaDOC
                </p>
                <p class="content-header" id="content-header-date-range"> 
                    ${body["dateRange"]}
                </p>
                <div class="row container-content mt-3">
                    <div id="company-title">
                        ${body["companyName"]}
                    </div>
                </div>
            </div>
            <div class="row mt-4" style="flex-grow:1; text-align: center;">
                <p class="content-header">
                    Pág. ${body.page}
                </p>
            </div>
        </header>
        
        <div>
            ${page}
        </div>
    </body>

    <style>

        body {
            z-index: -1;
        }

        header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    
        .container-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            
            font-family: 'Nunito', sans-serif;
        }

        .container-header p:nth-child(1) {
            font-weight: 700;
            font-size: 20px;
        }

        .container-header p:nth-child(2) {
            font-weight: 300;
            font-size: 15px;
        }

        #company-title {
            font-family: 'Nunito', sans-serif;
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        .content-header p {
            margin: 0;
        }
    </style>

`;
}
