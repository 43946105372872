<div class="container-fluid reset-container">
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex flex-column align-items-center justify-content-center">
        <h4 class="heading"> {{ "Change Password" | translate }} </h4>
        @if ( !showError ) {
          <p class="erorr-msg">
            {{ errorMsg }}
          </p>
        }
      </div>
    </div>
    
    <!-- <p class="subheading">Please enter your email below</p> -->
    @if ( !hideForm ) {
      <div class="row">
        <div class="col-12">
          <mat-card class="reset-card">
            <mat-card-content>
              <form
                class="example-form"
              [formGroup]="changepasswordForm"
              (ngSubmit)="changePasswordSubmit()"
            >
              <p style="text-align: initial; color: #46535b; font-weight: 600">
                {{
                  "Please enter your new password and confirm password." | translate
                }}
              </p>
      
              <mat-form-field
                class="example-full-width my-form-field"
                style="width: 100%"
              >
                <mat-label>{{ 'Old Password' | translate }}</mat-label>
                <input
                  [type]="oldPasswordHide ? 'password' : 'text'"
                  matInput
                  formControlName="oldPassword"
                  required
                />
                <mat-icon
                  class="hideShow"
                  matSuffix
                  (click)="oldPasswordHide = !oldPasswordHide"
                  >{{ oldPasswordHide ? "visibility_off" : "visibility" }}</mat-icon
                >
                <mat-error
                  *ngIf="changepasswordForm.get('oldPassword').hasError('required')"
                >
                  {{ "Old Password is Required." | translate }}
                </mat-error>
                <mat-error
                  *ngIf="changepasswordForm.get('oldPassword').hasError('pattern')"
                >
                  {{
                    "Please enter valid old password. The Password should contain minimum 8 characters at least one numeric value and at least one alphabet"
                      | translate
                  }}
                </mat-error>
              </mat-form-field>
              <br /><br />
              <mat-form-field
                class="example-full-width my-form-field"
                style="width: 100%"
              >
                <mat-label>{{ 'New Password' | translate }}</mat-label>
                <input
                  [type]="passwordHide ? 'password' : 'text'"
                  matInput
                  formControlName="password"
                  required
                />
                <mat-icon
                  class="hideShow"
                  matSuffix
                  (click)="passwordHide = !passwordHide"
                  >{{ passwordHide ? "visibility_off" : "visibility" }}</mat-icon
                >
      
                <mat-error
                  *ngIf="changepasswordForm.get('password').hasError('required')"
                >
                  {{ "Password is Required." | translate }}
                </mat-error>
                <mat-error
                  *ngIf="changepasswordForm.get('password').hasError('pattern')"
                >
                  {{
                    "Please enter valid password. The Password should contain minimum 8 characters at least one numeric value and at least one alphabet"
                      | translate
                  }}
                </mat-error>
                <!-- <mat-error
                  *ngIf="changepasswordForm.get('password').hasError('minlength')"
                >
                  {{ "Password should be minimum of 8 characters." | translate }}
                </mat-error> -->
              </mat-form-field>
              <br /><br />
              <mat-form-field
                class="example-full-width my-form-field"
                style="width: 100%"
              >
                <mat-label>{{ 'Confirm Password' | translate }}</mat-label>
                <input
                  [type]="confirmPasswordHide ? 'password' : 'text'"
                  matInput
                  formControlName="confirmPassword"
                  required
                />
                <mat-icon
                  class="hideShow"
                  matSuffix
                  (click)="confirmPasswordHide = !confirmPasswordHide"
                  >{{
                    confirmPasswordHide ? "visibility_off" : "visibility"
                  }}</mat-icon
                >
                <mat-error
                  *ngIf="
                    changepasswordForm.get('confirmPassword').hasError('required')
                  "
                >
                  {{ "Confirm Password is Required." | translate }}
                </mat-error>
                <mat-error
                  *ngIf="
                    changepasswordForm.get('confirmPassword').hasError('pattern')
                  "
                >
                  {{
                    "Please enter valid confirm password. The Password should contain minimum 8 characters at least one numeric value and at least one alphabet"
                      | translate
                  }}
                </mat-error>
                <mat-error
                  *ngIf="
                    changepasswordForm.get('confirmPassword').hasError('mustMatch')
                  "
                >
                  {{ "Password and confirm password do not match" | translate }}
                </mat-error>
              </mat-form-field>
      
              <div class="reset-button-row">
                <button
                  [disabled]="loading"
                  mat-raised-button
                  class="change-password-button"
                >
                  {{ "Change" | translate }}
                </button>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    }
  </div>
</div>
