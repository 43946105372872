import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, Event } from "@angular/router";
import { I18nService } from "./core/i18n.service";
import { environment } from "src/environments/environment";
import paquetes from "../../package.json";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  currentUrl: string;
  previousUrl: string;

  constructor(private router: Router, private i18nService: I18nService) {
    console.log(`Versión ${paquetes?.version}`);
  }

  ngOnInit() {
    // Setup translations
    this.i18nService.init(
      environment.defaultLanguage,
      environment.supportedLanguages
    );

    if (localStorage.getItem("language") === null) {
      this.i18nService.language = environment.defaultLanguage;
    } else {
      this.i18nService.language = localStorage.getItem("language");
    }

    this.loadRouting();
  }

  public loadRouting(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        let token =
          sessionStorage.getItem("provider-credentials") ||
          localStorage.getItem("provider-credentials");
        if (token != null && this.currentUrl === "/login") {
          this.router.navigate(["/"]);
        }
      }
    });
  }
}
