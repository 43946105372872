<div class="reset-container">
  <app-header></app-header>
  <div class="container-fluid mt-4">
    <div class="row">
      <div class="col-12 d-flex flex-column justify-content-center align-items-center">
        <h4 class="heading">{{ "Set New Password" | translate }}</h4>

        <p *ngIf="showError; else notError" style="font-weight: bold; color: red">
          {{ errorMsg }}
        </p>
    
        <ng-template #notError>
          <p class="requirements-list-heading">
            {{ "It must meet the following characteristics" | translate }}:
          </p>
    
          <ul class="requirements-list">
            <li
              class="list-icon default"
              [ngClass]="{
                check:
                  this.changepasswordForm.dirty &&
                  this.containsAnyLetters(
                    this.changepasswordForm.get('password').value
                  ),
                error:
                  this.changepasswordForm.dirty &&
                  !this.containsAnyLetters(
                    this.changepasswordForm.get('password').value
                  )
              }"
            >
              {{ "Use at least one letter" | translate }}.
            </li>
            <li
              class="list-icon default"
              [ngClass]="{
                check:
                  this.changepasswordForm.dirty &&
                  this.containsNumbers(
                    this.changepasswordForm.get('password').value
                  ),
                error:
                  this.changepasswordForm.dirty &&
                  !this.containsNumbers(
                    this.changepasswordForm.get('password').value
                  )
              }"
            >
              {{ "Use at least one number" | translate }}.
            </li>
            <li
              class="list-icon default"
              [ngClass]="{
                check:
                  this.changepasswordForm.dirty &&
                  this.changepasswordForm.get('password').value.length >= 8,
                error:
                  this.changepasswordForm.dirty &&
                  this.changepasswordForm.get('password').value.length < 8
              }"
            >
              {{
                "Have a length greater than or equal to 8 characters" | translate
              }}.
            </li>
          </ul>
        </ng-template>
      </div>

      <div class="col-12 d-flex justify-content-center">
        <mat-card class="col-3 mt-3 reset-card" *ngIf="!hideForm">
          <mat-card-content>Cambia la contraseña

            <form
            class="reset-form"
            [formGroup]="changepasswordForm"
            (ngSubmit)="changePasswordSubmit()"
          >
            <p class="enter-new-password">
              {{ "Enter a new password" | translate }}:
            </p>
            <mat-form-field
              class="full-width"
            >
              <mat-label>{{ 'New Password' | translate }}</mat-label>
              <input
                [type]="passwordHide ? 'password' : 'text'"
                matInput
                formControlName="password"
                required
              />
              <mat-icon matSuffix (click)="passwordHide = !passwordHide">{{
                passwordHide ? "visibility_off" : "visibility"
              }}</mat-icon>
            </mat-form-field>
            <br /><br />
            <mat-form-field
              class="full-width"
            >
              <mat-label>{{ 'Confirm Password' | translate }}</mat-label>
              <input
                [type]="confirmPasswordHide ? 'password' : 'text'"
                matInput
                formControlName="confirmPassword"
                required
              />
              <mat-icon
                matSuffix
                (click)="confirmPasswordHide = !confirmPasswordHide"
                >{{
                  confirmPasswordHide ? "visibility_off" : "visibility"
                }}</mat-icon
              >
            </mat-form-field>
    
            <p
              class="error-text"
              *ngIf="
                this.pressedSubmit &&
                (!this.containsAnyLetters(
                  this.changepasswordForm.get('password').value
                ) ||
                  !this.containsNumbers(
                    this.changepasswordForm.get('password').value
                  ) ||
                  this.changepasswordForm.get('password').value.length < 8)
              "
            >
              {{ "Your password must meet the above characteristics" | translate }}.
            </p>
    
            <p
              class="error-text"
              *ngIf="
                this.pressedSubmit &&
                this.changepasswordForm.get('password').value !==
                  this.changepasswordForm.get('confirmPassword').value
              "
            >
              {{ "Your password must match" | translate }}
            </p>
    
            <div class="reset-button-row">
              <button [disabled]="loading" mat-raised-button>
                {{ "Guardar" | translate }}
              </button>
            </div>
          </form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>  
  </div>
</div>
