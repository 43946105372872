import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../core/authentication.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as passwordHash from "js-sha512";
import { ErrorStateMatcher } from "@angular/material/core";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder,
} from "@angular/forms";
import { MustMatch } from "./must-match.validator";
import { HttpErrorResponse } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "../shared/shared.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  changepasswordForm: UntypedFormGroup;
  verificationCode: string;
  passwordHide = true;
  confirmPasswordHide = true;
  errorMsg: any;
  showError: boolean = false;
  hideForm = false;
  loading: boolean = false;
  pressedSubmit: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private translationService: TranslateService,
    private sharedService: SharedService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.verificationCode = params["verificationCode"];
    });
  }

  ngOnInit() {
    let token =
      sessionStorage.getItem("provider-credentials") ||
      localStorage.getItem("provider-credentials");
    if (this.verificationCode != null && this.verificationCode != undefined) {
      console.log("verifying code...");
      console.log("credenciales", JSON.parse(token));
      this.verifyCode();
    } else if (token != null) {
    } else {
      this.router.navigate(["/login"]);
    }

    let passwordregex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,15}$/;
    this.changepasswordForm = this.fb.group(
      {
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(passwordregex),
            Validators.minLength(8),
            Validators.maxLength(15),
          ],
        ],
        confirmPassword: ["", [Validators.required]],
      },
      {
        validator: MustMatch("password", "confirmPassword"),
      }
    );
  }

  verifyCode() {
    this.authService
      .codeVerificationAdminProvider(this.verificationCode)
      .subscribe(
        (res: any) => {},
        (error: HttpErrorResponse) => {
          this.errorMsg = error.error.errors;
          this.hideForm = true;
          this.showError = true;
          // this.changepasswordForm.disable();
        }
      );
  }

  changePasswordSubmit() {
    this.pressedSubmit = true;
    if (this.changepasswordForm.invalid) {
      return;
    }
    this.loading = true;
    this.showError = false;
    let hashedPassword = passwordHash.sha512(
      this.changepasswordForm.value.password
    );
    this.authService.resetPasswordAdminProvider(hashedPassword).subscribe(
      (res: any) => {
        this.loading = false;
        this.translationService
          .get("Password changed successfully")
          .subscribe((text: string) => {
            this.sharedService.showMessage(text, "success");
          });
        this.router.navigate(["/dashboard"]);
      },
      (error: HttpErrorResponse) => {
        console.log("error", error);
        this.loading = false;
        this.showError = true;
        this.errorMsg = error.error.errors.messages[0];
      }
    );
  }

  containsNumbers(str) {
    return /\d/.test(str);
  }

  containsAnyLetters(str) {
    return /[a-zA-Z]/.test(str);
  }
}
